import Images from '../assets/images';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleError, isNotNullUndefined, isNotNullUndefinedBlank } from '../helpers/common';
import { useGetExchangeAssets } from "../services/exchange.service";
import IResponse from "../types/response";
import { MessageHandler } from '../helpers/websocketmanager';
import { useAssetMarketWatchData } from '../helpers/datacontext';
import { formatNumberToFixed } from "../helpers/commaformattednumber";
import { storage } from '../utils/storage';

interface ExchangeAsset {
    assetName: string;
    baseSymbol: string;
    exchangeSymbol: string;
    pricePrecision: number;
    assetLogoUrl: string;
    aggTrade: string;
    ticker: string;
    oldLastPrice: string;
    lastPrice: string;
    percentage: string;
}

const AssetMarketWatch = () => {
    const navigate = useNavigate();
    const [exchangeAssets, setExchangeAssets] = useState<ExchangeAsset[]>([]);
    const { socketMessages, removeMessage, currentAsset, updateCurrentAsset } = useAssetMarketWatchData();
    const [searchTerm, setSearchTerm] = useState("");

    const handleWebSocketMessage: MessageHandler = (coin, message) => {
        if (isNotNullUndefined(message.data)) {
            setExchangeAssets((exchangeAssets) => {
                const index = exchangeAssets.findIndex((data: any) => data.aggTrade === message.stream);
                if (index !== -1) {
                    const aggTradeAsset = exchangeAssets[index];
                    let oldPrice = aggTradeAsset.lastPrice;
                    aggTradeAsset.lastPrice = parseFloat(message.data.p).toFixed(aggTradeAsset.pricePrecision);
                    if (aggTradeAsset.lastPrice !== oldPrice) {
                        aggTradeAsset.oldLastPrice = oldPrice;
                    }
                    if (currentAsset?.baseSymbol === aggTradeAsset.baseSymbol) {
                        updateCurrentAsset(aggTradeAsset);
                    }
                }
                const index1 = exchangeAssets.findIndex((data: any) => data.ticker === message.stream);
                if (index1 !== -1) {
                    const tickerAsset = exchangeAssets[index1];
                    tickerAsset.percentage = parseFloat(message.data.P).toFixed(tickerAsset.pricePrecision);
                    if (currentAsset?.baseSymbol === tickerAsset.baseSymbol) {
                        updateCurrentAsset(tickerAsset);
                    }
                }
                return [...exchangeAssets];
            });
        }
    };

    useEffect(() => {
        if (socketMessages.length > 0) {
            const latestSocketMessage = socketMessages[socketMessages.length - 1];
            handleWebSocketMessage("", latestSocketMessage.message);
            removeMessage(latestSocketMessage.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socketMessages]);

    const getExchangeAssets = useGetExchangeAssets({
        onSuccess: (response: IResponse) => {
            if (isNotNullUndefined(response.data) && response.data.length > 0) {
                let exchangeAssetsData = response.data.sort(
                    (a: { exchangeSymbol: string }, b: { exchangeSymbol: any }) => {
                        return a.exchangeSymbol.localeCompare(b.exchangeSymbol);
                    }
                );
                const updatedExchangeAssets = exchangeAssetsData.map((x: any) => {
                    return {
                        assetName: x.assetName,
                        baseSymbol: x.baseSymbol,
                        exchangeSymbol: x.exchangeSymbol,
                        pricePrecision: x.pricePrecision,
                        assetLogoUrl: x.assetLogoUrl,
                        aggTrade: x.exchangeSymbol.toLowerCase() + "@aggTrade",
                        ticker: x.exchangeSymbol.toLowerCase() + "@ticker",
                        oldLastPrice: parseFloat("0").toFixed(x.pricePrecision),
                        lastPrice: parseFloat("0").toFixed(x.pricePrecision),
                        percentage: parseFloat("0").toFixed(x.pricePrecision)
                    };
                });
                setExchangeAssets(updatedExchangeAssets);
                const asset = updatedExchangeAssets.find((data: any) => data.baseSymbol === "BTC");
                if (asset !== null) {
                    updateCurrentAsset(asset);
                }
            }
        },
        onError: (error: any) => {
            handleError(error);
        },
    });

    const onGetExchangeAssets = async () => {
        if (isNotNullUndefinedBlank(storage.getToken())) {
            getExchangeAssets.mutate(1);
        }
    };

    useEffect(() => {
        if (isNotNullUndefinedBlank(storage.getToken())) {
            if (parseInt(storage.getUserStatusId() ?? "10") <= 8) {
                onGetExchangeAssets();
            } else {
                navigate('/');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onRedirectCoinProfile = (exchangeSymbol: string) => {
        let userRoleId = storage.getUserRoleId() ?? "0";
        if (parseInt(userRoleId) !== 1) {
            const asset = exchangeAssets.find((data: any) => data.exchangeSymbol === exchangeSymbol);
            if (asset !== null) {
                updateCurrentAsset(asset);
            }
            navigate(`/account/asset/${exchangeSymbol}`);
        }
    }

    const filteredExchangeAssets = exchangeAssets.filter((exchangeAsset) => {
        const assetName = exchangeAsset?.assetName?.toLowerCase();
        const baseSymbol = exchangeAsset?.baseSymbol?.toLowerCase();
        const searchTermLowerCase = searchTerm?.toLowerCase();
        return assetName.includes(searchTermLowerCase) || baseSymbol.includes(searchTermLowerCase);
    });

    return (
        <div className="absolute group-hover:block hidden">
            <div className="bg-black-russian border border-image-radius rounded top-8 absolute -left-12 w-[290px] xl:-left-7 md:-left-36 md:w-[435px]">
                <div className="md:flex relative pt-2 px-2">
                    <div className="inline-flex items-center justify-center absolute left-0 top-1 h-full w-6 ml-6">
                        <img src={Images.magnifer} alt="Magnifer" />
                    </div>
                    <input
                        id="search"
                        type="text"
                        name="search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onClick={() => { setSearchTerm("") }}
                        className="text-15 text-white font-normal pl-14 pr-4 bg-grey-ebony-Clay w-full h-[42px] focus:outline-none"
                        placeholder="Search here"
                    />
                </div>
                <div className="overflow-auto min-h-[50px] max-h-[235px] px-2.5">
                    <table className="w-full mb-2.5">
                        <thead className="sticky top-0 bg-black-russian">
                            <tr className="border-b-2 border-grey-ebony-Clay mb-1">
                                <th className="px-4 py-3 pb-2 text-start text-xs md:text-sm whitespace-nowrap font-semibold text-white tracking-0.16 min-w-[130px]">
                                    Symbol</th>
                                <th className="px-4 py-3 text-start text-xs md:text-sm whitespace-nowrap font-semibold text-white tracking-0.16 min-w-[130px]">
                                    Last Price</th>
                                <th className="px-4 py-3 text-start text-xs md:text-sm whitespace-nowrap font-semibold text-white tracking-0.16 min-w-[130px]">
                                    24h %</th>
                            </tr>
                        </thead>
                        <tbody className="mt-1">
                            {filteredExchangeAssets.map((asset: any, index) => (
                                <tr key={asset.exchangeSymbol} className="hover:bg-black-blackRock">
                                    <td className="py-3 px-2 flex items-center" onClick={() => onRedirectCoinProfile(asset.exchangeSymbol)}>
                                        <div className="bg-jaguar w-7 h-7 flex justify-center items-center rounded-full">
                                            <img src={asset.assetLogoUrl} className="w-4 h-4 rounded-full" alt="" />
                                        </div>
                                        <span className="text-start text-xs whitespace-nowrap  font-normal text-white tracking-0.1 ml-1.5">{asset.exchangeSymbol}</span>
                                    </td>
                                    <td className={`py-3 px-2 text-start text-xs whitespace-nowrap  font-normal ${asset.lastPrice >= asset.oldLastPrice ? 'text-emerald' : 'text-persian'} tracking-0.16`}>
                                        {formatNumberToFixed(asset.lastPrice.toString(), 2)}
                                    </td>
                                    <td className={`py-3 px-2 text-start text-xs whitespace-nowrap font-normal ${asset.percentage >= 0 ? 'text-emerald' : 'text-persian'} tracking-0.16`}>{formatNumberToFixed(asset.percentage.toString(), 2)}%</td>
                                </tr>
                            ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default AssetMarketWatch;