import { useState, ChangeEvent, useEffect, useRef } from 'react';
import Images from "../assets/images/index";
import { useNavigate } from "react-router-dom";
import { useCompleteProfile, useFetchUser, useUpdateUserProcessStatus, useFetchCountry } from "../services/user.service";
import { ICompleteProfile, ICountry } from "../types/user";
import { storage } from "../utils/storage";
import 'react-phone-input-2/lib/style.css';
import { checkPasswordStrength } from '../helpers/passwordstrength';
import { isNotNullUndefinedBlank, statusWiseOnboardingRedirectToPage, isUserStatusHasPermission, getTimezoneDisplayName, handleEmptySpaces, preventEmptySpaces } from '../helpers/common';
import IResponse from '../types/response';
import * as Sentry from "@sentry/react";

const CompleteProfile = () => {
    const navigate = useNavigate();
    const [completeProfile, setCompleteProfile] = useState<ICompleteProfile>({
        fullName: "",
        password: "",
        email: "",
        mobileNo: "",
        referralCode: "",
        countryId: 1,
        timezoneId: ""
    });

    completeProfile.email = storage.getEmailId() ?? "";
    const [selectedCountry, setSelectedCountry] = useState<any>();
    const [country, setCountry] = useState<ICountry[]>([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [fullNameError, setfullNameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [formResponseError, setFormResponseError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const userStatusCode = useRef("");

    const checkValidation = (field: string) => {
        let isValid = true;
        setfullNameError('');
        setPasswordError('');
        completeProfile.fullName = completeProfile.fullName.trim().replace(/\s+/g, ' ');
        if ((field === "password" || field === "both")) {
            if (!isNotNullUndefinedBlank(completeProfile.password)) {
                setPasswordError('Password is required');
            } else {
                let password = completeProfile.password.split('').length;
                if ((password > 0 && password < 4)) {
                    setPasswordError('Password must be at least 4 characters.');
                    isValid = false;
                }
            }
        }
        if (!isNotNullUndefinedBlank(completeProfile.fullName) && (field === "fullName" || field === "both")) {
            setfullNameError('FullName is required.');
            isValid = false;
        } else {
            (document.querySelector('[name="fullName"]') as any).value = "";
        }
        return isValid;
    };

    const inputChange = (event: ChangeEvent<HTMLInputElement>) => {
        let { name, value } = event.target;
        if (name === "fullName") {
            value = value.replace(/[^a-zA-Z\s]/g, '');
        }
        if (name === "password") {
            setPasswordScore(checkPasswordStrength(value));
        }
        setCompleteProfile({ ...completeProfile, [name]: value });
    };

    const logout = async () => {
        window.localStorage.clear();
        navigate('/');
    };

    const connectBinance = async () => {
        userStatusCode.current = "ACTIVE";
        updateUserProcessStatusMutation.mutate({ "userId": storage.getUserId(), "statusCode": userStatusCode.current });
        navigate('/connect/binance/info');
    }

    const fetchUserMutation = useFetchUser({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200) {
                completeProfile.fullName = response.data.fullName;
                storage.setEmailId(response.data.email);
                completeProfile.email = storage.getEmailId() ?? "";
                if (isNotNullUndefinedBlank(response.data.loginProvider)) {
                    storage.setLoginProvider(response.data.loginProvider);
                }
                setCompleteProfile({ ...completeProfile });
            }
            else {
                setFormResponseError(response.statusMessage);
            }
        },
        onError: (error) => {
            Sentry.captureException(error);
            setFormResponseError(error.message);
        }
    });

    const fetchUser = async () => {
        fetchUserMutation.mutate({});
    };

    const getCountryList = () => {
        const countryListString = storage.getCountryList();
        return countryListString ? JSON.parse(countryListString) : [];
    };

    const fetchCountryMutation = useFetchCountry({
        onSuccess: (response: IResponse) => {
            if (response !== null && response !== undefined) {
                response.data = response;
                setCountry(response.data);
                storage.setCountryList(JSON.stringify(response.data));
                const storedCountryList = getCountryList();
                const options = storedCountryList.find((country: { countryId: number; }) => country.countryId === 1) || {
                    countryName: '',
                    dialingCode: '',
                    flagImageUrl: ''
                };
                handleCountryCodeChange(options);
            }
        },
        onError: (error: any) => {
            alert(error.message);
        },
    });

    const fetchCountry = async () => {
        fetchCountryMutation.mutate({});
    };

    const handleCountryCodeChange = (option: any) => {
        if (Array.isArray(option.label)) {
            const slicedLabel = option.label.slice(0, 2);
            const modifiedOption = { ...option, label: slicedLabel };
            setSelectedCountry(modifiedOption);
            setIsDropdownOpen(false);
        } else {
            setSelectedCountry(option);
            setIsDropdownOpen(false);
        }
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (value.length <= 10) {
            setPhoneNumber(value);
        }
    };


    const completeProfileMutation = useCompleteProfile({
        onSuccess: (response) => {
            if (response.statusCode === 200) {
                setIsLoading(false);
                storage.setUserId(response.data.userID);
                userStatusCode.current = "PROFILE_UPDATED"
                updateUserProcessStatusMutation.mutate({ "userId": storage.getUserId(), "statusCode": userStatusCode.current });
            }
            else {
                setIsLoading(false);
                setFormResponseError(response.statusMessage);
            }
        },
        onError: (error) => {
            setIsLoading(false);
            Sentry.captureException(error);
            setFormResponseError(error.message);
        }
    });

    const onCompleteProfile = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (checkValidation("both")) {
            setIsLoading(true);
            setCompleteProfile((prevState) => ({
                ...prevState,
                mobileNo: phoneNumber.toString(),
            }));
            completeProfileMutation.mutate({ completeProfile: { ...completeProfile, mobileNo: phoneNumber } });
        }
    };

    const updateUserProcessStatusMutation = useUpdateUserProcessStatus({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200) {
                if (userStatusCode.current === "PROFILE_UPDATED") {
                    storage.setUserStatusId(2);
                    if (isNotNullUndefinedBlank(completeProfile.mobileNo)) {
                        navigate('/registration/verify');
                    } else {
                        connectBinance();
                    }
                }
                if (userStatusCode.current === "ACTIVE")
                    storage.setUserStatusId(1);
            }
            else {
                setIsLoading(false);
                setFormResponseError(response.statusMessage);
            }
        },
        onError: (error) => {
            setIsLoading(false);
            Sentry.captureException(error);
            setFormResponseError(error.message);
        }
    });

    // const handlePhoneNumberChange = (value: any, data: any) => {
    //     const phoneNumber = value.slice(data.dialCode.length);
    //     const updatedRegister = {
    //         ...completeProfile,
    //         mobileNo: phoneNumber.toString(),
    //         countryId: parseInt(data.dialCode)
    //     };
    //     setCompleteProfile(updatedRegister);
    //     setPhoneNumber(value);
    // };

    //#region Password
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const specialChars = '!@#$%^&*()-_=+[]{};:<>?';
    const allCharacters = [lowercase, uppercase, numbers, specialChars];

    const getRandomCharacter = (characters: any) => {
        const randomType = characters[Math.floor(Math.random() * characters.length)];
        const randomIndex = Math.floor(Math.random() * randomType.length);
        return randomType[randomIndex];
    };
    const onAutoGeneratePassword = () => {
        const password =
            getRandomCharacter(lowercase) +
            getRandomCharacter(uppercase) +
            getRandomCharacter(numbers) +
            getRandomCharacter(specialChars) +
            Array.from({ length: 4 }, () => getRandomCharacter(allCharacters)).join('');
        setCompleteProfile({ ...completeProfile, password: password });
        setPasswordScore(checkPasswordStrength(password));
        checkValidation('password');
    };
    const [showPassword, setShowPassword] = useState(false);

    const handlePasswordVisibility = () => {
        if (showPassword)
            setShowPassword(false);
        else if (!showPassword)
            setShowPassword(true);
    };
    const [passwordScore, setPasswordScore] = useState<number>(0);
    //#endregion Password

    useEffect(() => {
        const storedCountryList = getCountryList();
        if (storedCountryList && storedCountryList.length > 0) {
            setCountry(storedCountryList);
            const options = storedCountryList.find((country: { countryId: number; }) => country.countryId === 1) || {
                countryName: '',
                dialingCode: '',
                flagImageUrl: ''
            };
            handleCountryCodeChange(options);
        } else {
            fetchCountry();
        }

        if (isUserStatusHasPermission(5) || isNotNullUndefinedBlank(storage.getUserExchangeStatusId())) {
            navigate(statusWiseOnboardingRedirectToPage());
        }
        fetchUser();
        setCompleteProfile({ ...completeProfile, timezoneId: getTimezoneDisplayName() });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="bg-black-russian">
            <div className="grid grid-cols-1 lg:grid-cols-2 mx-auto lg-block max-h-full min-h-screen">
                <div className='hidden lg:block'>
                    <div className="left mx-auto w-full xl:p-0 bg-pattern-1 bg-cover bg-no-repeat bg-center h-full">
                        <div className='flex justify-center'>
                            <img
                                className="mt-[75px] lg:w-56 w-36 sm:w-48 flex"
                                src={Images.ailtralogo}
                                alt="Ailtra Logo" draggable="false" />
                        </div>
                        <div className='flex justify-center items-center'>
                            <div className='w-[562px]'>
                                <div className='flex justify-center my-8 lg:my-[100px]'>
                                    <img
                                        className="bg-contain w-1/2 lg:w-3/5 3xl:w-4/6 bg-no-repeat"
                                        src={Images.welcome}
                                        alt="welcome Logo" draggable="false" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right bg-white rounded-t-none p-4 lg:p-0 lg:rounded-tl-3xl lg:rounded-bl-3xl lg:rounded-tr-none h-full">
                    <div className='w-full flex justify-center'>
                        <div className='w-36 h-1/5'>
                            <img
                                className="w-full mt-4 lg:hidden block"
                                src={Images.mobilelogo}
                                alt="Mobile Logo" draggable="false" />
                        </div>
                    </div>
                    <div className='flex justify-center items-center mt-8 lg:mt-0 lg:h-full'>
                        <div className='w-[340px] 2xl:w-[388px]'>
                            <div>
                                <h1 className="text-xl md:text-3xl 2xl:text-[34px] font-semibold subpixel-antialiased">Complete Profile 📑</h1>
                                <p className="text-sm md:text-base font-normal text-blue-gulf sm:leading-6 tracking-0.16 mt-2 lg:mt-3"> Complete your Profile: Simple Steps to Enhance Your Online Presence </p>
                            </div>
                            <div className="mt-6">
                                <form onSubmit={onCompleteProfile}>
                                    <label className={`block relative border-blue-hawkes px-4 py-2 mt-6 bg-blue-alice border shadow-sm focus:outline-none focus:border-blue-rock focus:ring-blue-rock ${fullNameError ? 'border-red-500 hover:border-red-500' : 'border-blue-hawkes focus:border-blue-rock hover:border-hover'} `} >
                                        <span className="block text-xs font-semibold text-black-pearl">Fullname</span>
                                        <input type="text" name="fullName" className="placeholder-grey-storm  bg-blue-alice focus:outline-none text-sm w-full pt-1"
                                            value={completeProfile.fullName} onChange={inputChange} onBlurCapture={handleEmptySpaces} placeholder="Enter your Fullname"
                                        // onBlur={() => checkValidation("fullName")} 
                                        />
                                    </label>
                                    {fullNameError && <p className="text-red-500 text-xs font-semibold mt-1 absolute">{fullNameError}</p>}
                                    <label className={`block relative border-blue-hawkes px-4 py-2 mt-6 bg-blue-alice border shadow-sm focus:outline-none focus:border-blue-rock focus:ring-blue-rock ${'border-blue-hawkes focus:border-blue-rock'}`}>
                                        <div className='flex justify-between items-center'>
                                            <div className='w-full pointer-events-none opacity-70 caret-transparent'>
                                                <span className="block text-xs font-semibold text-black-pearl"> Email </span>
                                                <input type="email" name="email" className="placeholder-grey-storm  bg-blue-alice focus:outline-none text-sm w-full pt-1"
                                                    value={completeProfile.email} onChange={inputChange} onBlurCapture={handleEmptySpaces} placeholder="Enter your email address" />
                                            </div>
                                            <div className='flex items-center relative group flex-col group'>
                                                <img src={Images.verified} alt={"verified"} className='w-6' />
                                                <div className="absolute bottom-0 flex-col items-center hidden mb-6 mr-2 group-hover:flex">
                                                    <span className="relative z-10 p-3  text-xs leading-4 text-white whitespace-no-wrap rounded-md bg-grey-bunker shadow-lg 
                                                    w-37.5"> {'Verified Email'}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                    <button type='button' className="mt-6 flex justify-end text-10 2xl:text-xs text-emerald underline mb-1 cursor-pointer select-none w-full outline-none" onClick={onAutoGeneratePassword}>
                                        Auto Generate Password
                                    </button>
                                    <label htmlFor="password" className={`block relative border-blue-hawkes px-4 py-2 bg-blue-alice border shadow-sm focus:outline-none focus:border-blue-rock focus:ring-blue-rock ${passwordError ? 'border-red-500 hover:border-red-500' : 'border-blue-hawkes focus:border-blue-rock hover:border-hover'}`}>
                                        <div className='flex justify-between items-center'>
                                            <div className='w-full'>
                                                <span className="block text-xs font-semibold text-black-pearl"> Password </span>
                                                {showPassword ? (
                                                    <input
                                                        type="text"
                                                        id="password"
                                                        name="password"
                                                        className="placeholder-grey-storm bg-blue-alice focus:outline-none text-sm w-full pt-1.5"
                                                        value={completeProfile.password}
                                                        // onBlur={() => checkValidation("password")}
                                                        onChange={inputChange}
                                                        onKeyDown={preventEmptySpaces}
                                                        placeholder="Enter your password"
                                                    />
                                                ) : (
                                                    <input
                                                        type="password"
                                                        id="password"
                                                        name="password"
                                                        className="placeholder-grey-storm bg-blue-alice focus:outline-none text-sm w-full pt-1.5"
                                                        value={completeProfile.password}
                                                        onChange={inputChange}
                                                        onKeyDown={preventEmptySpaces}
                                                        // onBlur={() => checkValidation("password")}
                                                        placeholder="Enter your password"
                                                        autoComplete="new-password"
                                                    />
                                                )}
                                            </div>
                                            <div className='flex items-center'>
                                                {showPassword ? (
                                                    <button type='button' className='outline-none' onClick={handlePasswordVisibility}>
                                                        <img
                                                            className="w-6 h-6 outline-none"
                                                            src={Images.eyeopen}
                                                            alt="open-eye"
                                                        />
                                                    </button>
                                                ) : (
                                                    <button type='button' className='outline-none' onClick={handlePasswordVisibility}>
                                                        <img
                                                            className="w-6 h-6 outline-none"
                                                            src={Images.eye}
                                                            alt="close-eye"
                                                        />
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </label>
                                    <div className="flex -mx-1 mt-2">
                                        {Array.from({ length: 5 }).map((_, i) => (
                                            <div key={i} className="w-1/5 px-1">
                                                <div
                                                    className={`h-1 rounded-xl transition-colors ${i < passwordScore
                                                        ? passwordScore <= 2
                                                            ? "bg-fire-brick"
                                                            : passwordScore <= 3
                                                                ? "bg-burnt-sienna"
                                                                : passwordScore <= 4
                                                                    ? "bg-rob-roy"
                                                                    : "bg-kelly-green"
                                                        : "bg-blue-hawkes"
                                                        }`}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    {passwordError && <p className="text-red-500 text-xs font-semibold absolute mt-4.5">{passwordError}</p>}
                                    <div className="flex justify-between mt-1">
                                        <span className='text-10 2xl:text-xs font-light text-grey-storm'>
                                            Must be at least 4 character long
                                        </span>
                                        <span className="text-10 font-semibold text-gradient bg-gradient bg-clip-text" >{passwordScore <= 3 ? 'Weak' : passwordScore <= 4 ? 'Medium' : 'Strong'}</span>
                                    </div>

                                    {/* <label htmlFor='phone-input' className="block border-blue-hawkes px-4 py-2 pb-0 mt-6 bg-blue-alice border shadow-sm focus:outline-none focus:border-blue-rock focus:ring-blue-rock hover:border-hover">
                                        <span className="block text-xs font-semibold text-black-pearl"> Mobile number </span>
                                        <div className='country-code'>
                                            <PhoneInput
                                                country={'in'}
                                                searchStyle={{
                                                    width: '248px',
                                                    margin: '0px 9px 5px 9px',
                                                    border: '1px solid #d4d7e3',
                                                    background: '#f7fbff',
                                                    color: 'black',
                                                    padding: '10px 8px'
                                                }}
                                                dropdownStyle={{
                                                    width: '320px',
                                                    maxHeight: '285px',
                                                    left: '-14px'
                                                }}
                                                inputProps={{
                                                    id: 'phone-input',
                                                }}
                                                enableSearch={true}
                                                countryCodeEditable={false}
                                                searchPlaceholder="Search for your country"
                                                placeholder="Enter your phone number"
                                                value={phoneNumber}
                                                onChange={handlePhoneNumberChange}
                                            />
                                        </div>
                                    </label> */}

                                    <div className="flex items-center w-full mt-6">
                                        <div className="relative min-w-[28%]">
                                            <div className="bg-blue-alice border-blue-hawkes text-black-pearl text-xs font-semibold cursor-pointer px-2 py-2 h-[60px] border shadow-sm focus:outline-none focus:border-blue-rock focus:ring-blue-rock hover:border-hover flex justify-between items-center" onClick={toggleDropdown}>
                                                <span className={`text-black-pearl flex justify-between items-center w-full gap-2.5 ${selectedCountry ? 'text-black-pearl' : ''}`}>
                                                    {selectedCountry ? `(${selectedCountry.dialingCode})` : ''}
                                                    <div className='w-full h-full'>
                                                        <img src={selectedCountry?.flagImageUrl} className="w-5" alt="Country Flag" />
                                                    </div>
                                                </span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <path d="M11.9465 5.45331H7.79316H4.05317C3.41317 5.45331 3.09317 6.22664 3.5465 6.67997L6.99983 10.1333C7.55317 10.6866 8.45317 10.6866 9.0065 10.1333L10.3198 8.81997L12.4598 6.67997C12.9065 6.22664 12.5865 5.45331 11.9465 5.45331Z" fill="black" />
                                                </svg>
                                            </div>
                                            {isDropdownOpen && (
                                                <div className="absolute mt-1 w-max max-h-56 overflow-auto z-10 bg-blue-alice border-blue-hawkes text-black-pearl text-xs font-semibold cursor-pointer border shadow-sm focus:outline-none focus:border-blue-rock focus:ring-blue-rock hover:border-hover">
                                                    {country.map((country) => (
                                                        <div key={country.countryId} className="py-2 px-3 hover:bg-gray-300 hover:text-black-pearl text-sm cursor-pointer flex items-center" onClick={() => handleCountryCodeChange(country)}>
                                                            <img src={country.flagImageUrl} alt={`${country.countryName} flag`} className="w-5 mr-2" />
                                                            <span>{country.countryName} ({country.dialingCode})</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                        <label className="block border-blue-hawkes px-4 py-2 bg-blue-alice border shadow-sm focus:outline-none focus:border-blue-rock focus:ring-blue-rock hover:border-hover w-[72%]">
                                            <span className="block text-xs font-semibold text-black-pearl">Mobile number</span>
                                            <input
                                                type="number"
                                                value={phoneNumber}
                                                onChange={handlePhoneNumberChange}
                                                onKeyDown={preventEmptySpaces}
                                                className="placeholder-grey-storm bg-blue-alice focus:outline-none text-sm w-full pt-1"
                                                placeholder="Enter your phone number"
                                            />
                                        </label>
                                    </div>

                                    <label className="block border-blue-hawkes px-4 py-2 mt-6 bg-blue-alice border shadow-sm focus:outline-none focus:border-blue-rock focus:ring-blue-rock hover:border-hover">
                                        <span className="block text-xs font-semibold text-black-pearl"> Referral code </span>
                                        <input type="text" name="referralCode" className="placeholder-grey-storm bg-blue-alice focus:outline-none text-sm w-full pt-1"
                                            value={completeProfile.referralCode} onChange={inputChange} onKeyDown={preventEmptySpaces} placeholder="Enter code here" />
                                    </label>
                                    <button
                                        type="submit"
                                        disabled={isLoading}
                                        className={`relative w-full flex items-center justify-center py-3.5 cursor-pointer font-bold text-sm transition-all text-white mt-4 sm:mt-8.5 btn-hover-animation focus-visible:bg-[right_center] hover:bg-[right_center] outline-none ${isLoading ? 'bg-gradient-hover opacity-60' : 'bg-gradient-hover'}`} >
                                        {isLoading ? (
                                            <p className='flex items-center'>
                                                <span className='w-4 h-4 border-2 border-dashed animate-spin rounded-full mr-2'>
                                                </span>
                                                Completing Profile...
                                            </p>
                                        ) : (
                                            'COMPLETE PROFILE'
                                        )}
                                    </button>
                                    <div className="text-center flex items-center justify-center" onClick={connectBinance}>
                                        <button type="button" className="outline-none flex justify-center items-center">
                                            {/* <span className="text-base font-semibold text-gradient bg-gradient bg-clip-text mt-1">Skip</span> */}
                                        </button>
                                    </div>
                                    <p className="text-red-500 text-xs font-semibold mt-1 absolute">{formResponseError}</p>
                                </form>
                                <div className="relative my-4 flex justify-center items-center"></div>
                                <div className="text-center flex items-center justify-center" onClick={logout}>
                                    <button type="button" className="outline-none flex justify-center items-center"><img src={Images.logouticon} draggable="false" alt="Logout" className="mr-2"></img> <span className="text-base font-semibold underline">Log out</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CompleteProfile;