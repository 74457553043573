import { ReactNode } from "react";
import Header from "./header";
import BacktestingNavbar from "./backtestingnavbar";

type BacktestingMainLayoutProps = {
    children: ReactNode;
};

const BacktestingMainLayout = ({ children }: BacktestingMainLayoutProps) => {
    return (
        <div className="flex flex-row min-h-screen">
            <BacktestingNavbar></BacktestingNavbar>
            <main className="main flex flex-col flex-grow transition-all duration-150 ease-in md:pl-[96px] lg:pl-[354px] bg-black-russian w-[calc(100%-354px)]">
                <Header></Header>
                {children}
            </main>
        </div>
    );
};
export default BacktestingMainLayout;