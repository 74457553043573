import { useState, useEffect, useRef } from "react";
import Images from "../assets/images/index";
import { useNavigate } from "react-router-dom";
import { useFetchUser, useSendVerificationCode, useVerifyUserVerificationCode } from "../services/user.service";
import { useOtp } from '../helpers/otp';
import { storage } from "../utils/storage";
import IResponse from "../types/response";
import { isNotNullUndefinedBlank, isUserStatusHasPermission, statusWiseOnboardingRedirectToPage } from '../helpers/common';
import * as Sentry from "@sentry/react";

const ForgotPasswordVerify = () => {
  const verificationData = { userId: "", email: "", codeType: "" };

  const navigate = useNavigate();
  const { codes, setCode, handleOtpInput, handleOtpKeyDown, resendTimer, resetTimer, handleOtpPaste } = useOtp();
  const userId = useRef("");
  const email = useRef("");
  const codeType = useRef("");
  const [isVerifying, setIsVerifying] = useState(false);
  const verificationModel = useRef(verificationData);
  const [verifyKeyError, setVerifyKeyError] = useState('');
  const [formResponseError, setFormResponseError] = useState('');
  const isSuccessResponse = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const checkValidation = (field: string) => {

    let isValid = true;
    if (field === "verifyKeyError") {
      if (codes.filter(x => x === "").length > 0) {
        setVerifyKeyError('Please enter OTP');
        isValid = false;
      } else {
        setVerifyKeyError('');
      }
    }
    return isValid;
  };

  useEffect(() => {
    userId.current = storage.getUserId() ?? "";
    email.current = storage.getEmailId() ?? "";
    codeType.current = storage.getCodeType() ?? "email";
    if (!isUserStatusHasPermission(1)) {
      navigate(statusWiseOnboardingRedirectToPage());
    } else {
      if (isNotNullUndefinedBlank(email.current) && isNotNullUndefinedBlank(codeType.current)) {
        verificationModel.current.userId = userId.current;
        verificationModel.current.email = email.current;
        verificationModel.current.codeType = codeType.current;
        onSendForgotPasswordVerificationCode();
      };
    }
    autoVerifyOTP();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codes]);

  const onSendForgotPasswordVerificationCode = async () => {
    sendForgotPasswordVerificationCodeMutation.mutate(verificationModel);
  };

  const sendForgotPasswordVerificationCodeMutation = useSendVerificationCode({
    onSuccess: (response: IResponse) => {
      if (response.statusCode === 200) {
        setFormResponseError(response.statusMessage);
      }
      setTimeout(() => { setFormResponseError("") }, 5000);
    },
    onError: (error) => {
      setIsLoading(false);
      Sentry.captureException(error);
      setFormResponseError(error.message);
    }
  });

  const reSendVerificationCodeMutation = useSendVerificationCode({
    onSuccess: () => {
      isSuccessResponse.current = true;
      setFormResponseError("OTP sent")
      setTimeout(() => { isSuccessResponse.current = false; setFormResponseError(""); }, 5000);
    },
    onError: (error) => {
      Sentry.captureException(error);
      setFormResponseError(error.message);
    }
  });

  const onVerify = async (e?: React.FormEvent) => {
    if (e) e.preventDefault();
    if (checkValidation("verifyKeyError")) {
      setIsLoading(true);
      let code = codes.join('');
      setIsVerifying(true);
      verifiyVerificationCodeMutation.mutate({
        userId: "00000000-0000-0000-0000-000000000000",
        email: email.current,
        verificationCode: code.replace(/\s+/g, ''),
        codeType: codeType.current
      });
      setIsVerifying(false);
    }
  };

  const verifiyVerificationCodeMutation = useVerifyUserVerificationCode({
    onSuccess: (response) => {
      if (response.statusCode === 200) {
        storage.setToken(response.data.token);
        storage.clearCodeType();
        fetchUserMutation.mutate({});
      }
      else {
        setIsLoading(false);
        setFormResponseError(response.statusMessage);
      }
    },
    onError: (error) => {
      setIsLoading(false);
      Sentry.captureException(error);
      setFormResponseError(error.message);
    }
  });

  const fetchUserMutation = useFetchUser({
    onSuccess: (response) => {
      storage.setUserId(response.data.userId);
      navigate("/setpassword");
    },
    onError: (error) => {
      setIsLoading(false);
      Sentry.captureException(error);
      setFormResponseError(error.message);
    }
  })

  const onResend = () => {
    reSendVerificationCodeMutation.mutate({ email: email.current, codeType: codeType.current });
    resetTimer();
    setCode(Array(codes.length).fill(""));
  };

  const onRedirectToLogin = () => {
    window.localStorage.clear();
    navigate('/');
  };

  const onChangeEmailAddress = () => {
    navigate("/forgotpassword");
  }

  const autoVerifyOTP = async () => {
    if (codes.join('').length === 6 && !isVerifying) {
      await onVerify();
    }
  };


  return (
    <div className="bg-black-russian  min-h-screen">
      <div className="grid grid-cols-1 lg:grid-cols-2 mx-auto max-h-full min-h-screen">
        <div className='hidden lg:block'>
          <div className="left mx-auto w-full xl:p-0 bg-pattern-1 bg-cover bg-no-repeat bg-center h-full">
            <div className='flex justify-center'>
              <img
                className="mt-[75px] lg:w-56 w-36 sm:w-48 flex"
                src={Images.ailtralogo}
                alt="Ailtra Logo" draggable="false" />
            </div>
            <div className='flex justify-center items-center'>
              <div className='w-[562px]'>
                <div className='flex justify-center bg-contain my-8 w-full lg:my-[100px]'>
                  <img
                    src={Images.emailbox2} className="w-1/2 lg:w-2/3"
                    alt="Email Box" draggable="false" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="right bg-white flex justify-center items-center rounded-t-none p-4 lg:p-0 lg:rounded-tl-3xl lg:rounded-bl-3xl lg:rounded-tr-none h-full">
          <div className="w-[340px] 2xl:w-[388px]">
            <div className="flex justify-center">
              <img src={Images.email} alt="Email" className="md:w-72 w-56" />
            </div>
            <div className="mt-14">
              <h4 className="text-lg text-center font-bold">
                Verify Your Authorization
              </h4>
              <div>
                <p className="text-center text-sm md:text-15 font-normal sm:leading-6 text-grey-storm mt-6">
                  Enter the 6-digit verification code sent to <span className="font-semibold border-b border-grey-storm mx-1">{email.current}</span> to confirm your authorization for trading.
                </p>
              </div>
            </div>
            <form onSubmit={onVerify}>
              <div className="relative">
                <div
                  id="otp"
                  className="flex flex-row justify-center text-center px-2 mt-10">
                  {codes.map((digit, index) => (
                    <input
                      key={`key-${index + 1}`}
                      className="m-1 md:m-2 border border-blue-rock h-9 w-9 md:h-12 md:my-2 md:mx-3 md:w-12 text-center form-control rounded"
                      type="text"
                      id={`code-${index}`}
                      maxLength={1}
                      onBlur={() => checkValidation("verifyKeyError")}
                      value={digit}
                      onChange={(e) => handleOtpInput(e, index)}
                      onKeyDown={(e) => handleOtpKeyDown(e, index)}
                      onPaste={(e) => handleOtpPaste(e, index)}
                    // onKeyUp={(e) => autoVerifyOTP(e)}
                    />
                  ))}
                </div>
                {verifyKeyError && <p className="text-red-500 text-xs mt-1 font-semibold text-center absolute w-full">{verifyKeyError}</p>}
              </div>
              <div className="mt-16 relative max-[1440px]:mt-8">
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`relative w-full flex items-center justify-center py-3.5 cursor-pointer font-bold text-sm transition-all text-white mt-4 sm:mt-8.5 btn-hover-animation focus-visible:bg-[right_center] hover:bg-[right_center] outline-none ${isLoading ? 'bg-gradient-hover opacity-60' : 'bg-gradient-hover'}`} >
                  {isLoading ? (
                    <p className='flex items-center'>
                      <span className='w-4 h-4 border-2 border-dashed animate-spin rounded-full mr-2'></span>Verifying OTP...
                    </p>
                  ) : (
                    'AGREED & PROCEED'
                  )}
                </button>
                <p className={`${!isSuccessResponse.current ? 'text-persian' : 'text-emerald'} text-xs mt-1 text-center font-semibold absolute w-full`}>{formResponseError}</p>
                <div className="mt-6 flex justify-center items-center mb-10">
                  {resendTimer > 0 ? (
                    <p className="font-normal text-13 text-gray-400">
                      Resend OTP in {resendTimer}s
                    </p>
                  ) : (
                    <button onClick={onResend} type="button"
                      className="font-normal text-13 text-gray-400">
                      Resend OTP
                    </button>
                  )}
                </div>
                <div className="mt-10">
                  <div className="w-full flex justify-center">
                    <p className="text-base font-normal text-center underline cursor-pointer" onClick={onChangeEmailAddress}>{"Change email address"}</p>
                  </div>
                  <button className="outline-none cursor-pointer w-full" onClick={() => onRedirectToLogin()}>
                    <p className="flex justify-center align-middle items-center mt-8 lg:mt-12">
                      <img src={Images.arrowLeft} className="mr-3" alt="Arrow left" /> Back to login
                    </p>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ForgotPasswordVerify;
