import { post, get, patch } from "../helpers/config";
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

export const useAuthenticate = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await post("user/authenticate", data, false);
    }, options);
};

export const useFetchUser = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async () => {
        return await get("user/me");
    }, options);
};

export const useRegister = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await post("user/register", data.register, false);
    }, options);
};

export const useSendVerificationCode = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await post("user/send-code", data, false);
    }, options);
};

export const useVerifyUserVerificationCode = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (verifiedData: any) => {
        return await patch("user/verify-code", verifiedData, false);
    }, options);
};

export const useFacebookLogin = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (facebookData: any) => {
        return await post("user/authenticate/facebook", facebookData, false);
    }, options);
};

export const useGetUsers = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await get("user?pageNumber=" + data.pageNumber + "&pageSize=" + data.pageSize + "&registerstartDate=" + data.registerstartDate + "&registerendDate=" + data.registerendDate + "&accountStatusType=" + data.accountStatusType + "&userRoleId=" + data.userRoleId + "&searchKeyword=" + data.searchKeyword);
    }, options);
};

export const useGetUserClone = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await get("user/analytics?pageNumber=" + data.pageNumber + "&pageSize=" + data.pageSize + "&registerstartDate=" + data.registerstartDate + "&registerendDate=" + data.registerendDate + "&accountStatusType=" + data.accountStatusType + "&userRoleId=" + data.userRoleId + "&referralByUserId=" + data.referralByUserId + "&searchKeyword=" + data.searchKeyword + "&sortBy=" + data.sortBy);
    }, options);
};

export const useSetPassword = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await patch("user/change-password", data);
    }, options);
};

export const useCompleteProfile = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await patch("user/register/complete", data.completeProfile);
    }, options);
};

export const useVerifyVerificationCodeV2 = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await patch("user/verify-code", data);
    }, options);
};

export const useRandomQuote = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async () => {
        return await get("quote/random");
    }, options);
};

export const useUpdateUserProcessStatus = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await post("user/process/status/complete", data);
    }, options);
};

export const useUpdateUser = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await patch("user/update", data, false);
    }, options);
};

export const useGetAccessToken = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async () => {
        return await get("user/access-token");
    }, options);
};

export const useGetSystemAdminToken = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await post("user/system-admin-token", data);
    }, options);
};

export const useFetchCountry = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async () => {
        const response = await get("country/list");
        return response.data;
    }, options);
};

export const useGetUserBackTestingHistory = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await get("user/" + data.userId + "/backtesting/history");
    }, options);
};

export const useSendBackTestingUserVerificationCode = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await post("user/backtesting/send-code", data, false);
    }, options);
};