export function checkPasswordStrength(password: any) {
    if (!password) {
        return 0;
    }

    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialCharacter = /[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/.test(password);

    const isLengthValid = password.length >= 8;
    var score = 0;
    if (hasUppercase) {
        score += 1;
    }
    if (hasLowercase) {
        score += 1;
    }
    if (hasNumber) {
        score += 1;
    }
    if (hasSpecialCharacter) {
        score += 1;
    }
    if (hasUppercase && hasLowercase && hasNumber && hasSpecialCharacter && isLengthValid) {
        score += 1;
    }
    return score;
};