import { useEffect, useRef, useState } from "react";
import Images from "../assets/images";
import Datepicker from "react-tailwindcss-datepicker";
import { useGetUserBackTestingHistory } from "../services/user.service";
import { formatDateTime, formatNumberSystem, isNotNullUndefined } from "../helpers/common";
import IResponse from "../types/response";
import { storage } from "../utils/storage";
import { useNavigate } from "react-router-dom";

const BacktestingHistory = () => {
    const [orderType, setOrderType] = useState("All");
    const backtestingHistoryData = useRef<any>([]);
    const allBacktestingHistoryData = useRef<any>([]);
    // const [startDate, setStartDate] = useState<Date | null>(null);
    const [, setStartDateMillisecond] = useState<number>(0);
    const navigate = useNavigate();

    // const handleStartDateChange = (date: Date | null) => {
    //     setStartDate(date);
    //     setStartDateMillisecond(date ? date.getTime() : 0);
    // };

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const [dateRangeValues, setDateRangeValues] = useState({
        startDate: null,
        endDate: null
    });

    const handleDateRangeValueChange = (newValue: any) => {
        setDateRangeValues(newValue);
        setStartDateMillisecond((new Date(newValue.startDate).getTime() ?? 0));
        setStartDateMillisecond((new Date(newValue.endDate).getTime()) ?? 0);
    }

    const fetchUserBackTestingHistory = useGetUserBackTestingHistory({
        onSuccess: (response: IResponse) => {
            if (isNotNullUndefined(response.statusCode) && response.statusCode === 200 && response.data.length > 0) {
                allBacktestingHistoryData.current = response.data;
            }
        },
        onError: (error: any) => {
            alert(error.message);
        },
    });

    useEffect(() => {
        if (orderType !== "StartNewBot" && allBacktestingHistoryData.current.length <= 0) {
            let userId = storage.getDynamicDataJSON("backtestingUserPreferences", "userId");
            fetchUserBackTestingHistory.mutate({ userId: (userId ?? "") });
        }
        else {
            backtestingHistoryData.current = allBacktestingHistoryData.current.filter((x: any) => x.jobStatus === orderType);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderType]);

    const redirectToBackTestingDashboard = (id: string) => {
        navigate("/backtesting?id=" + id);
    }

    return (
        <div className="py-8 px-9">
            <div>
                <h2
                    className="flex items-center justify-between w-full"
                    data-accordion-target="#accordion-collapse-body-1"
                    aria-controls="accordion-collapse-body-1">
                    <span className="text-2xl text-white font-semibold">
                        Backtesting History
                    </span>
                    <p className="border border-t-0 border-grey-nobel w-[calc(100%-277px)]"></p>
                </h2>
            </div>
            <div id="myTabContent">
                <div className="bg-grey-jaguar border-grey-tuna border rounded mt-5">
                    <ul
                        className="flex flex-wrap text-base lg:text-xl font-bold text-center"
                        id="myTab"
                        role="tablist">
                        <li className="w-1/4">
                            <button
                                type="button"
                                onClick={() => setOrderType("All")}
                                className={`flex justify-center p-3 w-full ${orderType === "All"
                                    ? "text-gradient bg-clip-text border-bottom bg-gradient border-b-2"
                                    : "text-white font-normal"
                                    }`}>
                                <p
                                    className={`${orderType === "All"
                                        ? "bg-gradient text-gradient bg-clip-text w-max"
                                        : ""
                                        }`}>
                                    All Backtesting
                                </p>
                            </button>
                        </li>
                        <li className="w-1/4">
                            <button
                                type="button"
                                onClick={() => setOrderType("Running")}
                                className={`flex justify-center p-3 w-full ${orderType === "Running"
                                    ? "text-gradient bg-clip-text border-bottom bg-gradient border-b-2"
                                    : "text-white font-normal"
                                    }`}>
                                <p
                                    className={`${orderType === "Running"
                                        ? "bg-gradient text-gradient bg-clip-text w-max"
                                        : ""
                                        }`}>
                                    Running jobs
                                </p>
                            </button>
                        </li>
                        <li className="w-1/4">
                            <button
                                type="button"
                                onClick={() => setOrderType("Completed")}
                                className={`flex justify-center p-3 w-full ${orderType === "Completed"
                                    ? "text-gradient bg-clip-text border-bottom bg-gradient border-b-2"
                                    : "text-white font-normal"
                                    }`}>
                                <p
                                    className={`${orderType === "Completed"
                                        ? "bg-gradient text-gradient bg-clip-text w-max"
                                        : ""
                                        }`}>
                                    Completed
                                </p>
                            </button>
                        </li>
                        <li className="w-1/4">
                            <button
                                type="button"
                                onClick={() => setOrderType("StartNewBot")}
                                className={`flex justify-center p-3 w-full ${orderType === "StartNewBot"
                                    ? "text-gradient bg-clip-text border-bottom bg-gradient border-b-2"
                                    : "text-white font-normal"
                                    }`}>
                                <p
                                    className={`${orderType === "StartNewBot"
                                        ? "bg-gradient text-gradient bg-clip-text w-max"
                                        : ""
                                        }`}>
                                    Start new bot
                                </p>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            {orderType === "All" && (
                <div className="overflow-auto max-h-[calc(100vh-274px)] mt-5">
                    {allBacktestingHistoryData.current.map((data: any, ix: number) => (
                        <div key={ix} className="bg-grey-jaguar gap-6 flex justify-between p-4 mb-2.5 border rounded min-w-fit">
                            <div className="flex items-center gap-6 justify-start">
                                <div className="flex items-center min-w-40 gap-3">
                                    <div>
                                        <p className="text-3xl font-semibold text-grey-suit">#{ix + 1}</p>
                                    </div>
                                    <div>
                                        <p className="text-xs font-normal text-grey-suit">
                                            Job Status
                                        </p>
                                        {data.jobStatus === "Completed" ?
                                            <p className="text-10 font-semibold bg-camarone text-white rounded-full py-0.5 px-2 mt-1 w-max">
                                                Completed
                                            </p>
                                            : <></>}
                                    </div>
                                </div>
                                <div className="min-w-35">
                                    <p className="text-xs font-normal text-grey-suit">
                                        Initial Amount
                                    </p>
                                    <p className="text-sm font-semibold text-white mt-1">${formatNumberSystem(data.initialFutureWalletBalance, "INS")}</p>
                                </div>
                                <div className="min-w-35">
                                    <p className="text-xs font-normal text-grey-suit">
                                        Bot Start Date
                                    </p>
                                    <p className="text-sm font-semibold text-white mt-1">
                                        {formatDateTime(data.botStartTime, "DS MMS, YYYY")}
                                    </p>
                                </div>
                                {data.jobStatus === "Completed" ?
                                    <div className="min-w-30">
                                        <p className="text-xs font-normal text-grey-suit">
                                            Total P/L
                                        </p>
                                        <p className="text-sm font-semibold text-emerald mt-1">
                                            ${formatNumberSystem(data.lifetimeProfit, "INS")}
                                        </p>
                                    </div>
                                    : (
                                        <>
                                            <div className="min-w-30">
                                                <p className="text-xs font-normal text-grey-suit">
                                                    Current P/L
                                                </p>
                                                <p className="text-sm font-semibold text-emerald mt-1">
                                                    ${formatNumberSystem(data.lifetimeProfit, "INS")}
                                                </p>
                                            </div>
                                            <div className="min-w-35"> {/* condition here */}
                                                <p className="text-xs font-normal text-grey-suit">
                                                    Floating P/L
                                                </p>
                                                <p className="text-sm font-semibold text-white mt-1">${formatNumberSystem(data.floatingPnL, "INS")}</p>
                                            </div>
                                        </>)
                                }
                                {data.jobStatus === "Completed" ?
                                    <div className="min-w-35"> {/* on completed */}
                                        <p className="text-xs font-normal text-grey-suit">
                                            Total Account Balance
                                        </p>
                                        <p className="text-sm font-semibold text-white mt-1">${formatNumberSystem(data.futureWalletBalance, "INS")}</p>
                                    </div>
                                    : (
                                        <div className="min-w-35">
                                            <p className="text-xs font-normal text-grey-suit">
                                                Account Balance
                                            </p>
                                            <p className="text-sm font-semibold text-white mt-1">${formatNumberSystem(data.futureWalletBalance, "INS")}</p>
                                        </div>
                                    )
                                }
                                <div className="min-w-45">
                                    <p className="text-xs font-normal text-grey-suit">
                                        Bot Uptime Since
                                    </p>
                                    <p className="text-sm font-semibold text-white mt-1">
                                        {data.botUptimeSince}
                                    </p>
                                </div>
                            </div>
                            <div className="min-w-[330px] flex items-center justify-end gap-2">
                                <button
                                    type="button"
                                    className="w-full py-2 flex items-center justify-center gap-2 font-bold text-xs text-white border-image-radius rounded-full tracking-[0.28px] relative after:absolute after:top-0 after:left-0 after:w-full after:h-full after:opacity-0 after:bg-active-gradient after:transition after:duration-[0.4s] after:ease-out hover:after:opacity-100"
                                    onClick={() => redirectToBackTestingDashboard(data.userId)}
                                >
                                    <div className="w-4">
                                        <img src={Images.detailsicon} alt="Hold icon" />
                                    </div>
                                    Detailed View
                                </button>
                                {
                                    (data.jobStatus === "Completed") ?
                                        <button
                                            type="button"
                                            className="w-full py-2 flex items-center gap-2 justify-center font-bold text-xs text-white border-image-radius rounded-full tracking-[0.28px] relative after:absolute after:top-0 after:left-0 after:w-full after:h-full after:opacity-0 after:bg-active-gradient after:transition after:duration-[0.4s] after:ease-out hover:after:opacity-100"
                                        >
                                            <div className="w-4">
                                                <img src={Images.holdicon} alt="Hold icon" />
                                            </div>
                                            Stop Running Bot
                                        </button>
                                        : <></>
                                }
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {orderType === "Running" && (
                <div className="overflow-auto max-h-[calc(100vh-274px)] mt-5">
                    {backtestingHistoryData.current.map((data: any, ix: number) => (
                        (data.jobStatus === "Running") ? (
                            <div key={ix} className="bg-grey-jaguar gap-6 flex justify-between p-4 mb-2.5 border rounded min-w-fit">
                                <div className="flex items-center gap-6 justify-start">
                                    <div className="flex items-center min-w-35 gap-3">
                                        <div>
                                            <p className="text-3xl font-semibold text-grey-suit">#{ix + 1}</p>
                                        </div>
                                        <div>
                                            <p className="text-xs font-normal text-grey-suit">Job Status</p>
                                        </div>
                                    </div>
                                    <div className="min-w-35">
                                        <p className="text-xs font-normal text-grey-suit">Initial Amount</p>
                                        <p className="text-sm font-semibold text-white mt-1">${formatNumberSystem(data.initialFutureWalletBalance, "INS")}</p>
                                    </div>
                                    <div className="min-w-35">
                                        <p className="text-xs font-normal text-grey-suit">Bot Start Date</p>
                                        <p className="text-sm font-semibold text-white mt-1">{formatDateTime(data.botStartTime, "DS MMS, YYYY")}</p>
                                    </div>
                                    <div className="min-w-30">
                                        <p className="text-xs font-normal text-grey-suit">Current P/L</p>
                                        <p className="text-sm font-semibold text-emerald mt-1">${formatNumberSystem(data.lifetimeProfit, "INS")}</p>
                                    </div>
                                    <div className="min-w-35">
                                        <p className="text-xs font-normal text-grey-suit">Floating P/L</p>
                                        <p className="text-sm font-semibold text-white mt-1">${formatNumberSystem(data.floatingPnL, "INS") ?? 0}</p>
                                    </div>
                                    <div className="min-w-35">
                                        <p className="text-xs font-normal text-grey-suit">Account Balance</p>
                                        <p className="text-sm font-semibold text-white mt-1">${formatNumberSystem(data.futureWalletBalance, "INS")}</p>
                                    </div>
                                    <div className="min-w-45">
                                        <p className="text-xs font-normal text-grey-suit">Bot Uptime Since</p>
                                        <p className="text-sm font-semibold text-white mt-1">{data.botUptimeSince} ago</p>
                                    </div>
                                </div>
                                <div className="min-w-[330px] flex items-center justify-end gap-2">
                                    <button
                                        type="button"
                                        className="w-full py-2 flex items-center justify-center gap-2 font-bold text-xs text-white border-image-radius rounded-full tracking-[0.28px] relative after:absolute after:top-0 after:left-0 after:w-full after:h-full after:opacity-0 after:bg-active-gradient after:transition after:duration-[0.4s] after:ease-out hover:after:opacity-100"
                                        onClick={() => redirectToBackTestingDashboard(data.userId)}
                                    >
                                        <div className="w-4">
                                            <img src={Images.detailsicon} alt="Details icon" />
                                        </div>
                                        Detailed View
                                    </button>
                                    {
                                        (data.jobStatus === "Completed") ?
                                            <button
                                                type="button"
                                                className="w-full py-2 flex items-center gap-2 justify-center font-bold text-xs text-white border-image-radius rounded-full tracking-[0.28px] relative after:absolute after:top-0 after:left-0 after:w-full after:h-full after:opacity-0 after:bg-active-gradient after:transition after:duration-[0.4s] after:ease-out hover:after:opacity-100"
                                            >
                                                <div className="w-4">
                                                    <img src={Images.holdicon} alt="Hold icon" />
                                                </div>
                                                Stop Running Bot
                                            </button>
                                            : <></>
                                    }
                                </div>
                            </div>
                        ) : <></>
                    ))}
                    {backtestingHistoryData.current.length === 0 ?
                        <div key="no-data">
                            <p className="mt-3 w-full text-xl font-normal text-white text-center">No records found</p>
                        </div>
                        : <></>
                    }
                </div>
            )}

            {orderType === "Completed" && (
                <div className="overflow-auto max-h-[calc(100vh-274px)] mt-5">
                    {backtestingHistoryData.current.map((data: any, ix: number) => (
                        (data.jobStatus === "Completed") ? (
                            <div key={ix} className="bg-grey-jaguar gap-6 flex justify-between p-4 mb-2.5 border border-emerald rounded min-w-fit">
                                <div className="flex items-center gap-6 justify-start">
                                    <div className="flex items-center justify-start min-w-35 gap-3">
                                        <div>
                                            <p className="text-3xl font-semibold text-grey-suit">#{ix + 1}</p>
                                        </div>
                                        <div>
                                            <p className="text-xs font-normal text-grey-suit">
                                                Job Status
                                            </p>
                                            {data.jobStatus === "Completed" ?
                                                <p className="text-10 font-semibold bg-camarone text-white rounded-full py-0.5 px-2 mt-1 w-max">
                                                    Completed
                                                </p>
                                                : <></>
                                            }
                                        </div>
                                    </div>
                                    <div className="min-w-35">
                                        <p className="text-xs font-normal text-grey-suit">
                                            Initial Amount
                                        </p>
                                        <p className="text-sm font-semibold text-white mt-1">${formatNumberSystem(data.initialFutureWalletBalance, "INS")}</p>
                                    </div>
                                    <div className="min-w-35">
                                        <p className="text-xs font-normal text-grey-suit">
                                            Bot Start Date
                                        </p>
                                        <p className="text-sm font-semibold text-white mt-1">
                                            {formatDateTime(data.botStartTime, "DS MMS, YYYY")}
                                        </p>
                                    </div>
                                    <div className="min-w-30">
                                        <p className="text-xs font-normal text-grey-suit">Total P/L</p>
                                        <p className="text-sm font-semibold text-emerald mt-1">
                                            ${formatNumberSystem(data.lifetimeProfit, "INS")}
                                        </p>
                                    </div>
                                    <div className="min-w-35">
                                        <p className="text-xs font-normal text-grey-suit">
                                            Total Account Balance
                                        </p>
                                        <p className="text-sm font-semibold text-white mt-1">${formatNumberSystem(data.futureWalletBalance, "INS")}</p>
                                    </div>
                                    <div className="min-w-45">
                                        <p className="text-xs font-normal text-grey-suit">
                                            Bot Uptime Since
                                        </p>
                                        <p className="text-sm font-semibold text-white mt-1">
                                            {data.botUptimeSince} ago
                                        </p>
                                    </div>
                                </div>
                                <div className="min-w-[330px] flex items-center justify-end gap-2">
                                    <button
                                        type="button"
                                        className="w-full py-2 flex items-center justify-center gap-2 font-bold text-xs text-white border-image-radius rounded-full tracking-[0.28px] relative after:absolute after:top-0 after:left-0 after:w-full after:h-full after:opacity-0 after:bg-active-gradient after:transition after:duration-[0.4s] after:ease-out hover:after:opacity-100"
                                        onClick={() => redirectToBackTestingDashboard(data.userId)}
                                    >
                                        <div className="w-4">
                                            <img src={Images.detailsicon} alt="Detail icon" />
                                        </div>
                                        Detailed View
                                    </button>
                                    <button
                                        type="button"
                                        className="w-full py-2 flex items-center gap-2 justify-center font-bold text-xs text-white border-image-radius rounded-full tracking-[0.28px] relative after:absolute after:top-0 after:left-0 after:w-full after:h-full after:opacity-0 after:bg-active-gradient after:transition after:duration-[0.4s] after:ease-out hover:after:opacity-100"
                                    >
                                        <div className="w-5">
                                            <img src={Images.emailicon} alt="Hold icon" />
                                        </div>
                                        Email Report
                                    </button>
                                </div>
                            </div>
                        ) : <></>
                    ))}
                    {backtestingHistoryData.current.length === 0 ?
                        <div key="no-data">
                            <p className="mt-3 w-full text-xl font-normal text-white text-center">No records found</p>
                        </div>
                        : <></>
                    }
                </div>
            )}

            {orderType === "StartNewBot" && (
                <div className=" mt-10">
                    <div>
                        <h2 className="text-3xl font-semibold bg-gradient w-max text-gradient bg-clip-text">
                            Start New Backtesting
                        </h2>
                        <p className="text-white mt-2">
                            Enter the initial capital and starting date for your new
                            backtesting session.This allows you to simulate and analyze
                            different trading strategies in historical market conditions.
                        </p>
                    </div>
                    <div className="grid grid-cols-6 gap-4 mt-6">

                        <div className="grid">
                            <label className="text-white text-sm">Starting date
                                <Datepicker
                                    inputClassName="bg-grey-bunker w-full text-sm text-white mt-2 font-normal pl-2.5 pr-2.5 py-2.5 md:py-4 focus:outline-0"
                                    primaryColor={"green"}
                                    containerClassName="relative w-full datepicker-select-popup"
                                    value={dateRangeValues}
                                    placeholder={"Choose Your Start Date"}
                                    onChange={handleDateRangeValueChange}
                                    useRange={false}
                                    asSingle={true}
                                />
                            </label>
                        </div>
                        <div className="grid">
                            <label className="text-white text-sm">
                                Set Your Initial Investment

                                <div className="flex items-center">
                                    <span className="relative text-white left-5 top-[5px] -ml-2">
                                        $
                                    </span>
                                    <input
                                        type="number"
                                        name="firstLongOrderAmount"
                                        className="bg-grey-bunker w-full text-white font-normal text-sm pl-[20px] pr-2.5 py-2.5 md:py-4 focus:outline-0 mt-2"
                                    />
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4 mt-5">
                        <div>
                            <button
                                type="button"
                                className="w-full py-2.5 md:py-4 font-bold text-sm text-white btn-hover-animation hover:bg-[right_center] bg-gradient-hover tracking-[0.28px]"
                            >
                                START AI BOT
                            </button>
                        </div>
                    </div>
                    <div>
                        <p className="text-white mt-6">
                            <span className="font-semibold">Note:</span> In order to provide a
                            consistent experience for all users, we limit the number of
                            concurrent backtesting sessions to a maximum of 3, allowing
                            everyone a fair chance to access the service.
                        </p>
                    </div>

                </div>
            )}
        </div>
    );
};
export default BacktestingHistory;