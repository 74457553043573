import React from "react";

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (newPage: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
    currentPage,
    totalPages,
    onPageChange,
}) => {
    const pagesToShow = 3;
    const pagesToDisplay: any[] = [];

    if (totalPages <= pagesToShow) {
        for (let i = 1; i <= totalPages; i++) {
            pagesToDisplay.push(i);
        }
    } else {
        if (currentPage < pagesToShow) {
            for (let i = 1; i <= pagesToShow; i++) {
                pagesToDisplay.push(i);
            }
            pagesToDisplay.push("...");
        }
        else {
            pagesToDisplay.push("...");

            var first = currentPage === totalPages ? currentPage - 2 : currentPage - 1;
            var last = currentPage === totalPages ? currentPage : currentPage + 1;
            for (let i = first; i <= last; i++) {
                pagesToDisplay.push(i);
            }

            if (totalPages > last) {
                pagesToDisplay.push("...");
            }
        }
    }

    const handlePageChange = (newPage: any, index: number) => {
        if (typeof newPage === 'number') {
            if (newPage >= 1 && newPage <= totalPages) {
                onPageChange(newPage);
            }
        }
        else {
            currentPage = index === 0 ? currentPage - pagesToShow : currentPage + pagesToShow;
            if (currentPage <= 0) currentPage = 1;
            if (currentPage > totalPages) currentPage = totalPages;
            onPageChange(currentPage);
        }
    };

    return (
        <div className="flex items-center justify-end pt-2.5 sm:pt-5">
            <div>
                <nav className="inline-flex gap-2" aria-label="Pagination">
                    <button className="flex items-center justify-center text-13 w-7 h-7 sm:w-8 sm:h-8 rounded-full text-grey-storm p-1.5 bg-grey-jaguar border-grey-tuna border hover:bg-grey-tuna"
                        onClick={() => handlePageChange(1, 0)}>
                        <span className="sr-only">Previous</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M11.7266 12L12.6666 11.06L9.61329 8L12.6666 4.94L11.7266 4L7.72663 8L11.7266 12Z" fill="currentColor" />
                            <path d="M7.33332 12L8.27332 11.06L5.21998 8L8.27331 4.94L7.33331 4L3.33332 8L7.33332 12Z" fill="currentColor" />
                        </svg>
                    </button>
                    <button className="flex items-center justify-center text-13 w-7 h-7 sm:w-8 sm:h-8 rounded-full text-grey-storm p-1.5 bg-grey-jaguar border-grey-tuna border hover:bg-grey-tuna"
                        onClick={() => handlePageChange(currentPage - 1, 0)}>
                        <span className="sr-only">Previous</span>
                        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                        </svg>
                    </button>

                    {pagesToDisplay.map((page, index) => (
                        <button key={index} aria-current="page"
                            className={`flex items-center justify-center text-13 w-7 h-7 sm:w-8 sm:h-8 rounded-full text-white p-1.5 border border-[#ffffff0f] hover:bg-grey-tuna ${currentPage === page ? 'bg-gradient' : ''}`}
                            onClick={() => { handlePageChange(page, index); }}>{page}</button>
                    ))}

                    <button className="flex items-center justify-center text-13 w-7 h-7 sm:w-8 sm:h-8 rounded-full text-grey-storm p-1.5 bg-grey-jaguar border-grey-tuna border hover:bg-grey-tuna"
                        onClick={() => handlePageChange(currentPage + 1, 0)}>
                        <span className="sr-only">Next</span>
                        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                        </svg>
                    </button>
                    <button className="flex items-center justify-center text-13 w-7 h-7 sm:w-8 sm:h-8 rounded-full text-grey-storm p-1.5 bg-grey-jaguar border-grey-tuna border hover:bg-grey-tuna"
                        onClick={() => handlePageChange(totalPages, 0)}>
                        <span className="sr-only">Next</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M4.27337 4L3.33337 4.94L6.38671 8L3.33337 11.06L4.27337 12L8.27337 8L4.27337 4Z" fill="currentColor" />
                            <path d="M8.66668 4L7.72668 4.94L10.78 8L7.72668 11.06L8.66668 12L12.6667 8L8.66668 4Z" fill="currentColor" />
                        </svg>
                    </button>
                </nav>
            </div>
        </div >
    );
};

export default Pagination;