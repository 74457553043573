export type MessageHandler = (coin: string, message: any) => void;

class WebSocketManager {
    private websockets: { [coin: string]: WebSocket } = {};
    private messageHandlers: { [coin: string]: MessageHandler[] } = {};

    public connect(coin: string, url: string) {
        const ws = new WebSocket(url);
        ws.onopen = () => { console.log('WebSocket connection established for ' + coin); };
        ws.onmessage = (event) => this.handleMessage(coin, JSON.parse(event.data));
        this.websockets[coin] = ws;
    }

    public disconnect(coin: string) {
        if (this.websockets[coin]) {
            this.websockets[coin].close();
            delete this.websockets[coin];
        }
    }

    public addMessageHandler(coin: string, handler: MessageHandler) {
        if (!this.messageHandlers[coin]) {
            this.messageHandlers[coin] = [];
        }
        this.messageHandlers[coin].push(handler);
    }

    private handleMessage(coin: string, message: any) {
        const handlers = this.messageHandlers[coin];
        if (handlers) {
            handlers.forEach((handler) => handler(coin, message));
        }
    }
}

export default WebSocketManager;