import { ReactNode } from "react";
import Header from "./header";
import Navbar from "./navbar";

type MainLayoutProps = {
    children: ReactNode;
};

const MainLayout = ({ children }: MainLayoutProps) => {
    return (
        <div className="flex flex-row min-h-screen">
            <Navbar></Navbar>
            <main className="main flex flex-col flex-grow transition-all duration-150 ease-in md:pl-[96px] lg:pl-[264px] bg-black-russian w-[calc(100%-264px)]">
                <Header></Header>
                {children}
            </main>
        </div>
    );
};
export default MainLayout;