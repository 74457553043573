import { useEffect, useRef } from 'react';
import { useFetchUser, useAuthenticate, useUpdateUserProcessStatus } from '../services/user.service';
import { useNavigate } from "react-router-dom";
import { storage } from '../utils/storage';
import IResponse from '../types/response';
import { isNotNullUndefinedBlank, statusWiseOnboardingRedirectToPage } from '../helpers/common';
import * as Sentry from "@sentry/react";

const GoogleLoginRedirect = () => {
    const navigate = useNavigate();
    const exchangeAccounts: any = useRef([]);

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const code = urlSearchParams.get('code');

        if (code) {
            googleLogin.mutate({ code, loginProvider: "google" });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchUser = async () => {
        fetchUserMutation.mutate({});
    };

    const fetchUserMutation = useFetchUser({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200) {
                // redirect to dashboard if already registered.
                exchangeAccounts.current = (response.data.exchangeAccounts.length > 0) ? response.data.exchangeAccounts : [];
                let statusId = response.data.statusId;
                if (isNotNullUndefinedBlank(response.data.loginProvider))
                    storage.setLoginProvider(response.data.loginProvider);
                if (statusId === 1) {
                    storage.setUserStatusId(response.data.statusId);
                    storage.setEmailId(response.data.email);
                    storage.setUserId(response.data.userId);

                    if (isNotNullUndefinedBlank(response.data.phoneNumber)) {
                        storage.setCountryId(response.data.countryId)
                        storage.setPhoneNumber(response.data.phoneNumber)
                    }
                } else {
                    updateUserProcessStatusMutation.mutate({ "userId": response.data.userId, "statusCode": "REGISTERED" });
                }
                updateUserExchangeDetails();
            }
            else {
                alert(response.statusMessage);
            }
        },
        onError: (error) => {
            Sentry.captureException(error);
            alert(error.message);
        }
    });

    const googleLogin = useAuthenticate({
        onSuccess: async (response: IResponse) => {
            if (response.statusCode === 200) {
                storage.setToken(response.data.token);
                await fetchUser();
            }
            else {
                alert(response.statusMessage);
            }
        },
        onError: (error) => {
            Sentry.captureException(error);
            alert(error.message);
        }
    });

    const updateUserExchangeDetails = async () => {
        if (exchangeAccounts.current.length > 0) {
            storage.setExchangeId(exchangeAccounts.current[0].exchangeId);
            storage.setUserExchangeAccountId(exchangeAccounts.current[0].userExchangeAccountId);
            storage.setUserExchangeStatusId(exchangeAccounts.current[0].statusId);
        }
        navigate(statusWiseOnboardingRedirectToPage());
    }

    const updateUserProcessStatusMutation = useUpdateUserProcessStatus({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200) {
                storage.setUserStatusId(5);
                updateUserExchangeDetails();
            }
            else {
                alert(response.statusMessage);
            }
        },
        onError: (error) => {
            Sentry.captureException(error);
            alert(error.statusMessage);
        }
    });

    return (
        <button type="button" className="bg-indigo-500 ..." disabled>
            <svg className="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
            </svg>
            Processing...
        </button>
    );
};

export default GoogleLoginRedirect;