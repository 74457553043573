export function formatNumberWithCommas(numberString: any) {
    let number = parseFloat(numberString);
    const isSmall = (Math.abs(number) < 1);
    number = isSmall ? number : Math.round(number * 100) / 100;
    let formattedNumber = isSmall ? numberString : number.toFixed(2);
    if (isSmall)
        return formattedNumber;

    const [integerPart, decimalPart] = formattedNumber.split(".");
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `${formattedIntegerPart}.${decimalPart}`;
};

export function formatNumberToFixed(data: any, limit: number) {
    const parsedData = parseFloat(data.toString());
    const formattedNumber = parseFloat(formatNumberWithCommas(data.toString())).toFixed(limit);
    if (parsedData >= 1) {
        return formattedNumber;
    } else if (parsedData > 0 && parsedData < 1) {
        return formatNumberWithCommas(data.toString());
    } else {
        return formattedNumber;
    }
}