import Images from '../assets/images';
import { useState } from 'react';

const BacktestingNavbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(false);

    const handleModeChange = () => {
        setIsDarkMode(!isDarkMode);
    };

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };
    return (
        <div className="relative min-h-screen  md:flex">
            <button
                className="block md:hidden fixed z-50 top-5 left-4 items-center justify-center"
                onClick={toggleSidebar}>
                {isOpen ? (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-white"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                    </svg>
                ) : (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-white"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                )}
            </button>
            <div className='fixed z-50'>
                <div className={`w-[210px] md:w-[0px] md:py-0 md:px-0 fixed inset-y-0 left-0 transform md:relative transition-transform duration-300 ease-in-out z-50 ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}>
                    <div className="sidebar transition delay-700 duration-300 ease-in-out md:delay-700 md:duration-300 md:ease-in-out w-[210px] md:w-[96px] lg:w-[354px] fixed min-h-screen bg-grey-jaguar">
                        <div className="sidebar-header flex items-center justify-center">
                            <div className="inline-flex">
                                <img
                                    src={Images.ailtralogo}
                                    className="w-40 mt-8  hidden lg:inline-block"
                                    alt="Ailtralogo"
                                />
                                <img
                                    src={Images.ailtralogo2}
                                    className="w-12 mt-8 hidden md:inline-block lg:hidden"
                                    alt="Ailtralogo"
                                />
                            </div>
                        </div>
                        <button
                            className="block md:hidden fixed z-50 top-5 right-4"
                            onClick={toggleSidebar}>
                            {isOpen ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 text-white"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 text-white"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor">
                                </svg>
                            )}
                        </button>
                        <div className='block md:hidden px-6 mt-12'>
                            <div className='flex justify-between items-center'>
                                <div>
                                    <img
                                        src={Images.ailtralogo2}
                                        className="w-12 inline-block lg:hidden"
                                        alt="Ailtralogo"
                                    />
                                </div>
                                <div className="">
                                    <img className=" w-5 xl:w-6" src={Images.bellbing} alt="Bellbing" />
                                </div>
                                <div className="flex cursor-pointer">
                                    <button onClick={handleModeChange} className={`sun ${isDarkMode ? "bg-gradient w-8.5 h-[30px] md:w-[42px] md:h-8.5 px-2 py-1.5 flex justify-center" : "bg-grey-ebony-Clay opacity-50 hidden lg:block w-8.5 h-[30px] md:w-[42px] md:h-8.5 px-2 py-1.5"} `}>
                                        <img className="" src={Images.sun} alt="Sun" />
                                    </button>
                                    <button onClick={handleModeChange} className={`moon ${isDarkMode ? "bg-grey-ebony-Clay opacity-50 hidden lg:block w-8.5 h-[30px] md:w-[42px] md:h-8.5 px-2 py-1.5" : "bg-gradient w-8.5 h-[30px] md:w-[42px] md:h-8.5 px-2 py-1.5 flex justify-center"}`}>
                                        <img className="" src={Images.moon} alt="Moon" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='sidebar-content px-6 pt-6 lg:px-8 lg:pt-4.5 mb-[36px]'>
                                <p className='text-sm font-semibold text-white'>Unlock Your Crypto Fortune with Ailtra</p>
                            </div>
                            <div className='overflow-auto h-[calc(100vh-170px)]'>
                                <div className='flex justify-center items-center pl-[22px] gap-2.5'>
                                    <p className='text-sm font-semibold text-white flex justify-center items-center gap-3'><img src={Images.ailtrachoose} alt="Ailtra-choose" /> Why Choose Ailtra?</p>
                                    <p className='border border-t-0 border-bottom border-opacity-12 w-[calc(100%-184px)]'></p>
                                </div>
                                <div className='sidebar-content px-6 lg:pl-8 mt-[21px]'>
                                    <p className='text-sm text-white flex items-center'><img src={Images.monthlyprofit} className='mr-3' alt="Monthly-Profit" />Up to<span className='text-gradient bg-gradient bg-clip-text mx-1'>30%</span>monthly profits.</p>
                                    <p className='text-sm text-white flex items-center mt-[17px]'><img src={Images.dailyrate} className='mr-3' alt="Daily-Rate" />99% <span className='text-gradient bg-gradient bg-clip-text mx-1'>daily profit</span> rate.</p>
                                    <p className='text-sm text-white flex items-center mt-[17px]'><img src={Images.investment} className='mr-3' alt="Investment" /><span className='text-gradient bg-gradient bg-clip-text mr-1'>Double your investment</span> in 4 months.</p>
                                </div>
                                <div className='mt-[42px] flex justify-center items-center pl-[22px] gap-2.5'>
                                    <p className='text-sm font-semibold text-white flex justify-center items-center gap-3'><img src={Images.monthlyperformers} alt="monthly-performers" /> Monthly Top Performers</p>
                                    <p className='border border-t-0 border-bottom border-opacity-12 w-[calc(100%-216px)]'></p>
                                </div>
                                <div className='pl-10 pr-3 mt-[18px]'>
                                    <div>
                                        <table role="presentation" className='w-full'>
                                            <tbody>
                                                <tr className='border-b border-[#312D52]'>
                                                    <td className='w-[10%] text-sm font-normal text-grey-rock-blue pb-[14px]'>1</td>
                                                    <td className='flex items-center pb-[14px]'><img src={Images.topprofit} alt="Top-profit" />
                                                        <span className='text-sm font-normal text-white ml-2'>Hiren Bhuva</span>
                                                    </td>
                                                    <td className=' w-1/4 text-sm font-normal text-end text-white pb-[14px]'>500.25</td>
                                                </tr>
                                                <tr className='border-b border-[#312D52]'>
                                                    <td className='w-[10%] text-sm font-normal text-grey-rock-blue py-[14px]'>2</td>
                                                    <td className='flex items-center py-[14px]'><img src={Images.topprofit} alt="Top-profit" />
                                                        <span className='text-sm font-normal text-white ml-2'>Hiren Bhuva</span>
                                                    </td>
                                                    <td className=' w-1/4 text-sm font-normal text-end text-white py-[14px]'>500.25</td>
                                                </tr>
                                                <tr className='border-b border-[#312D52]'>
                                                    <td className='w-[10%] text-sm font-normal text-grey-rock-blue py-[14px]'>3</td>
                                                    <td className='flex items-center py-[14px]'><img src={Images.topprofit} alt="Top-profit" />
                                                        <span className='text-sm font-normal text-white ml-2'>Hiren Bhuva</span>
                                                    </td>
                                                    <td className=' w-1/4 text-sm font-normal text-end text-white py-[14px]'>500.25</td>
                                                </tr>
                                                <tr className='border-b border-[#312D52]'>
                                                    <td className='w-[10%] text-sm font-normal text-grey-rock-blue py-[14px]'>4</td>
                                                    <td className='flex items-center py-[14px]'><img src={Images.topprofit} alt="Top-profit" />
                                                        <span className='text-sm font-normal text-white ml-2'>Hiren Bhuva</span>
                                                    </td>
                                                    <td className=' w-1/4 text-sm font-normal text-end text-white py-[14px]'>500.25</td>
                                                </tr>
                                                <tr className='border-b border-[#312D52]'>
                                                    <td className='w-[10%] text-sm font-normal text-grey-rock-blue py-[14px]'>5</td>
                                                    <td className='flex items-center py-[14px]'><img src={Images.topprofit} alt="Top-profit" />
                                                        <span className='text-sm font-normal text-white ml-2'>Hiren Bhuva</span>
                                                    </td>
                                                    <td className=' w-1/4 text-sm font-normal text-end text-white py-[14px]'>500.25</td>
                                                </tr>
                                                <tr className='border-b border-[#312D52]'>
                                                    <td className='w-[10%] text-sm font-normal text-grey-rock-blue py-[14px]'>6</td>
                                                    <td className='flex items-center py-[14px]'><img src={Images.topprofit} alt="Top-profit" />
                                                        <span className='text-sm font-normal text-white ml-2'>Hiren Bhuva</span>
                                                    </td>
                                                    <td className=' w-1/4 text-sm font-normal text-end text-white py-[14px]'>500.25</td>
                                                </tr>
                                                <tr className='border-b border-[#312D52]'>
                                                    <td className='w-[10%] text-sm font-normal text-grey-rock-blue py-[14px]'>7</td>
                                                    <td className='flex items-center py-[14px]'><img src={Images.topprofit} alt="Top-profit" />
                                                        <span className='text-sm font-normal text-white ml-2'>Hiren Bhuva</span>
                                                    </td>
                                                    <td className=' w-1/4 text-sm font-normal text-end text-white py-[14px]'>500.25</td>
                                                </tr>
                                                <tr className='border-b border-[#312D52]'>
                                                    <td className='w-[10%] text-sm font-normal text-grey-rock-blue py-[14px]'>8</td>
                                                    <td className='flex items-center py-[14px]'><img src={Images.topprofit} alt="Top-profit" />
                                                        <span className='text-sm font-normal text-white ml-2'>Hiren Bhuva</span>
                                                    </td>
                                                    <td className=' w-1/4 text-sm font-normal text-end text-white py-[14px]'>500.25</td>
                                                </tr>
                                                <tr className='border-b border-[#312D52]'>
                                                    <td className='w-[10%] text-sm font-normal text-grey-rock-blue py-[14px]'>9</td>
                                                    <td className='flex items-center py-[14px]'><img src={Images.topprofit} alt="Top-profit" />
                                                        <span className='text-sm font-normal text-white ml-2'>Hiren Bhuva</span>
                                                    </td>
                                                    <td className=' w-1/4 text-sm font-normal text-end text-white py-[14px]'>500.25</td>
                                                </tr>
                                                <tr className='border-b border-[#312D52]'>
                                                    <td className='w-[10%] text-sm font-normal text-grey-rock-blue py-[14px]'>10</td>
                                                    <td className='flex items-center py-[14px]'><img src={Images.topprofit} alt="Top-profit" />
                                                        <span className='text-sm font-normal text-white ml-2'>Hiren Bhuva</span>
                                                    </td>
                                                    <td className=' w-1/4 text-sm font-normal text-end text-white py-[14px]'>500.25</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div>
                                        <button className='bg-gradient rounded-lg py-2.5 w-full'>
                                            <p className='text-sm font-normal text-white'>Daily Profit</p>
                                            <p className='text-base font-semibold text-white'>$25635.00</p>
                                        </button>
                                    </div>
                                </div>
                                <div className='bg-[#000] py-6 px-5 rounded-lg border-backtesting-radius mt-[25px] sticky bottom-0'>
                                        <div>
                                            <p className='text-white text-base text-center'>Start Your Free Bot Today!</p>
                                            <div className='flex justify-center mt-3.5'>
                                                <img src={Images.freebot} alt="Free-Bot" />
                                            </div>
                                            <button className='rounded-lg py-3.5 w-full mt-3 btn-hover-animation hover:bg-[right_center] bg-gradient-hover'>
                                                <p className='text-sm font-semibold text-white'>Get Started for Free!</p>
                                            </button>
                                            <p className='text-13 font-normal text-white mt-[15px] text-center'>💰 Imagine the profits from backtesting could be yours. 🚀 Your name on the leaderboard, mining $$$ in any
                                                market – bull or bear. Start today!</p>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default BacktestingNavbar;